import { Box } from "@twilio-paste/core/box";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { useDispatch, useSelector } from "react-redux";

import { changeExpandedStatus } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { containerStyles } from "./styles/EntryPoint.styles";

export const EntryPoint = () => {
    const dispatch = useDispatch();
    const expanded = useSelector((state: AppState) => state.session.expanded);

    return (
        <Box
            as="button"
            data-test="entry-point-button"
            onClick={() => dispatch(changeExpandedStatus({ expanded: !expanded }))}
            {...containerStyles}
        >
            {expanded ? (
                <ChevronDownIcon decorative={false} title="Minimize chat" size="sizeIcon100" />
            ) : (
                <div style={{ display: 'grid', alignContent: 'center', justifyContent: 'center'}}>
                    <img style={{ width: 35, height: 35 }} src="https://866ourvote.org/wp-content/themes/866ourvote/Logo%20White%20Transparent.png" alt="866 Our Vote Hotline" />
                </div>
            )}
        </Box>
    );
};
