import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";

import { containerStyles, titleStyles } from "./styles/Header.styles";

export const Header = ({ customTitle }: { customTitle?: string }) => {
    return (
        <Box as="header" {...containerStyles}>
            <Text as="h2" {...titleStyles}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img style={{ width: 35, marginRight: 16 }} src="https://866ourvote.org/wp-content/themes/866ourvote/Logo%20White%20Transparent.png" alt="866 Our Vote Hotline" />
                    {customTitle || "Live Chat"}
                </div>
            </Text>
        </Box>
    );
};
